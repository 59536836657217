<template>
  <v-container class="" fluid v-if="visita.supervisora">
    <v-row align="start" class="px-3 mx-auto">
      <!-- detalle de la visita #1-->
      <v-col>
        <v-card>
          <v-toolbar elevation="0">
            <v-toolbar-title
              ><strong class="primary--text">Visita ID:</strong>
              {{ visita.id }}</v-toolbar-title
            >

            <v-spacer></v-spacer>

            <!-- MODAL DIALOG - sección de comentarios -->
            <v-dialog v-model="dialog" width="100%">
              <!-- todo -->
              <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary lighten-2"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  text
                >
                  100 comentarios <v-icon small>mdi-comment</v-icon>
                </v-btn>
              </template> -->

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Seguimiento de la visita
                </v-card-title>

                <v-card-text>
                  <!-- registros antiguos -->
                  <table class="mb-2">
                    <thead>
                      <tr>
                        <td align="center">Fecha</td>
                        <td align="center">Usuario</td>
                        <td align="center">Asunto</td>
                        <td align="center">Detalle</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          valign="top"
                          class="td_titulo"
                          style="min-width: 150px"
                        >
                          {{ fnFiltroFecha(new Date()) }}
                        </td>
                        <td
                          valign="top"
                          class="td_titulo"
                          style="min-width: 120px"
                        >
                          Usuario Nombre
                        </td>
                        <td
                          valign="top"
                          class="td_titulo"
                          style="min-width: 120px"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Recusandae, id.
                        </td>
                        <td valign="top">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Tempora ducimus harum voluptatum vero tempore
                          aliquam, quas doloribus quibusdam quo, facilis
                          adipisci! Enim modi, suscipit harum nisi ipsam
                          expedita quod eveniet adipisci. Et incidunt laboriosam
                          distinctio expedita, ipsum repudiandae ducimus ratione
                          animi dicta corrupti fugiat voluptatum error labore
                          aspernatur eum numquam dolorum, consequatur in.
                          Cupiditate vel dicta saepe sed aperiam explicabo
                          molestias voluptate alias. Enim quas eligendi
                          accusamus soluta iste a aperiam odio rem ea aspernatur
                          autem, corrupti ut laboriosam excepturi iure tempore?
                          Eum quidem ea maxime excepturi in inventore voluptas
                          possimus deleniti eligendi sapiente? Exercitationem
                          fugit veritatis porro quo cupiditate officia
                          reiciendis ullam, dolorem veniam laboriosam ipsa sint
                          officiis vero voluptas? Nemo, iusto, veritatis
                          dignissimos, neque dolorum quaerat perferendis
                          eligendi aliquid consectetur non velit explicabo?
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- registros antiguos -->
                  <!-- crear nuevo registro -->
                  <table>
                    <tr>
                      <td
                        valign="top"
                        class="td_titulo"
                        style="min-width: 150px"
                      >
                        {{ fnFiltroFecha(new Date()) }}
                      </td>
                      <td
                        color="primary"
                        valign="top"
                        class="td_titulo"
                        style="min-width: 120px"
                      >
                        Usuario Nombre
                      </td>
                      <td valign="top" style="max-width: 170px">
                        <v-text-field
                          name="name"
                          label="Asunto"
                          id="idAsunto"
                          class="mr-5"
                        ></v-text-field>
                      </td>
                      <td style="min-width: 700px">
                        <v-text-field
                          name="name"
                          label="Detalle"
                          id="idDetalle"
                          class="mr-5"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-btn small elevation="2" color="green">agregar</v-btn>
                      </td>
                    </tr>
                  </table>
                  <!-- crear nuevo registro -->
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    Cerrar ventana
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- MODAL DIALOG - sección de comentarios -->
          </v-toolbar>

          <v-container fluid elevation="3">
            <v-card-text>
              <v-row>
                <!-- datos generales -->
                <v-col cols="6">
                  <v-row>
                    <!-- DATOS  DE LA SUPERVISORA -->
                    <v-col
                      ><v-subheader> Supervisora </v-subheader>

                      <v-divider></v-divider>

                      <table class="mb-2">
                        <tbody>
                          <tr>
                            <td valign="top" class="td_titulo">Usuario:</td>
                            <td class="td_detalle">
                              {{ visita.supervisora.alias }}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top" class="td_titulo">Código:</td>
                            <td class="td_detalle">
                              {{ visita.supervisora.codigo }}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top" class="td_titulo">Nombre:</td>
                            <td class="td_detalle">
                              {{ visita.supervisora.nombre_completo }}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top" class="td_titulo">División:</td>
                            <td class="td_detalle">
                              {{ visita.supervisora.division }}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top" class="td_titulo">Región:</td>
                            <td class="td_detalle">
                              {{ visita.supervisora.region }}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top" class="td_titulo">
                              Agente de comercio:
                            </td>
                            <td class="td_detalle">
                              {{ visita.supervisora.tipo }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <table>
                        <tbody>
                          <tr>
                            <td valign="top" class="td_titulo mt-2">
                              Usuario:
                            </td>
                            <td class="td_detalle">
                              {{ visita.supervisora.alias }}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top" class="td_titulo mt-2">
                              Batería:
                            </td>
                            <td
                              v-if="visita.supervisora.bateria != null"
                              class="td_detalle"
                              :title="getDate(visita.supervisora.bateria_fecha)"
                            >
                              {{ visita.supervisora.bateria }} %
                            </td>
                            <td
                              v-else
                              class="td_detalle"
                              title="Sin información disponible"
                            >
                              N/A
                            </td>
                          </tr>
                          <tr>
                            <td>GPS</td>

                            <td
                              v-if="visita.supervisora.gps_flag == 0"
                              :title="getDate(visita.supervisora.gps_fecha)"
                              class="red--text"
                            >
                              Apagado
                            </td>
                            <td
                              v-if="visita.supervisora.gps_flag == 1"
                              :title="getDate(visita.supervisora.gps_fecha)"
                              class="green--text"
                            >
                              Encendido
                            </td>
                          </tr>
                        </tbody>
                      </table> -->
                    </v-col>
                    <!-- /** FIN DATOS DE LA SUPERVISRA */ -->
                    <!-- /** 
                DETALLE TBL DATOS DE LA CONSEJERA
             */ -->
                    <v-col>
                      <v-subheader> Datos consejera </v-subheader>

                      <v-divider></v-divider>
                      <table class="mb-2">
                        <tbody>
                          <tr>
                            <td valign="top" class="td_titulo">Código:</td>
                            <td class="td_detalle">
                              {{ visita.consejera.codigo }}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top" class="td_titulo">Nombre:</td>
                            <td class="td_detalle">
                              {{ visita.consejera.nombre_completo }}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top" class="td_titulo">Dirección:</td>
                            <td class="td_detalle">
                              {{ visita.consejera.direccion }}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top" class="td_titulo">Teléfono:</td>
                            <td class="td_detalle">
                              {{ visita.consejera.telefono }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table>
                        <tbody>
                          <tr>
                            <td valign="top" class="td_titulo">Sector:</td>
                            <td class="td_detalle" width="55">
                              <span mr-2>{{ visita.consejera.sector }} </span>
                            </td>
                            <td valign="top" class="td_titulo">División:</td>
                            <td class="td_detalle" width="90">
                              <span mr-2>{{ visita.consejera.division }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top" class="td_titulo">Región:</td>
                            <td colspan="2" class="td_detalle" width="120">
                              <span mr-2>{{ visita.consejera.region }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top" class="td_titulo">
                              Ultima visita:
                            </td>
                            <td colspan="3" class="td_detalle" width="220">
                              <span mr-2>{{
                                fnFiltroFecha(visita.consejera.ultima_visita)
                              }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- /** 
                FIN DETALLE TBL DATOS DE LA CONSEJERA
                */ -->
                    </v-col>
                  </v-row>

                  <!-- DATOS DE LA VISITA -->
                  <v-subheader> Datos de la visita </v-subheader>

                  <v-divider></v-divider>
                  <table class="mb-2">
                    <tbody>
                      <tr>
                        <td valign="top" class="td_titulo">Fecha:</td>
                        <td class="td_detalle">
                          {{ fnFiltroFecha(visita.fecha) }}
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">
                          Registro de visita:
                        </td>
                        <td>
                          {{ visita.motivo_de_visita }}
                        </td>
                      </tr>
                      <tr class="py-4">
                        <td valign="top" class="td_titulo">
                          Visita coordenadas:
                        </td>
                        <td class="td_detalle">
                          <strong
                            ><v-icon
                              :class="fnGetColor(visita.motivo_de_visita_id)"
                              >mdi-label-variant</v-icon
                            >
                            lat:</strong
                          >
                          {{ visita.lat }}, <strong>lng:</strong>
                          {{ visita.lng }}
                        </td>
                      </tr>

                      <tr class="py-4" v-if="visita.pedido">
                        <td valign="top" class="td_titulo">
                          Entrega coordenadas:
                        </td>
                        <td class="td_detalle">
                          <strong
                            ><v-img
                              width="18"
                              style="margin-right: 1.2px"
                              class="d-inline-flex ml-1"
                              :src="require('@/assets/images/pedido-icon.png')"
                            ></v-img>

                            lat:</strong
                          >
                          {{ visita.pedido.DELIVERY_LAT }},
                          <strong>lng:</strong> {{ visita.pedido.DELIVERY_LON }}
                        </td>
                      </tr>

                      <tr>
                        <td valign="top" class="td_titulo">Comentarios:</td>
                        <td class="td_detalle">
                          {{ visita.observaciones }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- FIN DATOS DE LA VISITA -->
                </v-col>
                <!-- fin datos generales -->
                <!-- imagen -->
                <v-col cols="3">
                  <v-subheader> Imagen </v-subheader>
                  <v-divider></v-divider>
                  <v-img
                    class="grey lighten-5"
                    contain
                    :src="fnGetVisitaImagen(visita.image_dir)"
                  >
                  </v-img>
                </v-col>
                <!-- fin imagen -->
                <!-- mapa -->
                <v-col>
                  <v-subheader> Mapa </v-subheader>
                  <v-divider></v-divider>
                  <mapa
                    :prop_markers="[visita]"
                    :prop_visitadetalle="true"
                    :prop_polyline_enable="false"
                  />
                </v-col>
                <!-- fin mapa -->
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn text color=""> </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-col>
      <!-- detalle de la visita #1-->
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import mapa from '../components/mapa.vue';
import moment from 'moment';
export default {
  name: 'visitaDetalle',
  components: {
    mapa,
  },
  data() {
    return {
      dialog: false,
      visita: {},
    };
  },
  computed: {
    currentDate() {
      return moment().format('DD-MMMM YYYY HH:mm');
    },
  },
  mounted() {
    this.getVisitas(this.$route.params.id).then((result) => {
      this.visita = result;
    });
  },
  methods: {
    ...mapActions('Visitas', {
      getVisitas: 'get',
    }),
    getDate(date) {
      return date ? moment(date).utc().format('DD MMMM YYYY hh:mm a') : '';
    },
    fnGetColor(localTipoVisita) {
      return this.$store.state.visitas.filter(
        (store_visita) => store_visita.id == localTipoVisita
      )[0].textColorClass;
    },
    fnFiltroFecha(fecha) {
      return fecha
        ? moment(fecha).format('DD') +
            ' de ' +
            moment(fecha).format('MMMM YYYY') +
            ' a las ' +
            moment(fecha).utc().format('HH:mm a')
        : 'N/a';
    },
    fnGetVisitaImagen(dir) {
      return process.env.VUE_APP_FEATHERS_SERVER + dir;
    },
  },
};
</script>
<style scoped>
.td_titulo {
  font-weight: 700;
  color: black;
  margin-right: 15px;
}
.td_detalle {
  color: #424242;
}
</style>
